* {
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Work Sans", sans-serif;
    font-weight: 400;
    color: #fff;
    background-color: #0b091b;
    overflow-x: scroll;
}

@media screen and (min-width: 600px) {
    #root {
        width: 1280px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1280px) {
    #root {
        width: 100%;
    }
}
