.top-swiper .swiper-pagination {
    margin-top: 20px;
    width: 100%;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.top-swiper .swiper-pagination .swiper-pagination-bullet {
    width: 32px;
    height: 8px;
    background: #ffffff4d;
    cursor: pointer;
}
.top-swiper .swiper-pagination .swiper-pagination-bullet-active {
    background: #6149f7;
}

.highly-swiper .swiper-pagination {
    margin-top: 10px;
    width: 100%;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.highly-swiper .swiper-pagination .swiper-pagination-bullet {
    width: 32px;
    height: 8px;
    background: #ffffff4d;
    cursor: pointer;
}
.highly-swiper .swiper-pagination .swiper-pagination-bullet-active {
    background: #ffffff;
}
.highly-swiper-mobile .swiper-pagination {
    margin-top: 20px;
}
